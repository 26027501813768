import i18n from 'i18next';
import { REMOTE_AVAILABILITY_MESSAGE } from './common';

enum HardwareProcurementType {
  New = 'BUY_NEW',
  Serial = 'ELECTRIC_INVENTORY',
  SerialInternal = 'INTERNAL_INVENTORY',
  Storefront = 'HARDWARE_VAR',
}

enum ProvisioningLocationTypes {
  Remote = 'REMOTE',
  AtElectric = 'AT_ELECTRIC',
  DeviceHasAutoEnrollment = 'DEVICE_HAS_AUTO_ENROLLMENT',
  NoProvisioningSupport = 'NO_PROVISIONING_SUPPORT',
}

const HARDWARE_PROCUREMENT_LABELS = {
  [HardwareProcurementType.New]: 'Buy New Hardware',
  [HardwareProcurementType.Serial]: 'Add from Electric Inventory',
  [HardwareProcurementType.SerialInternal]: 'Add from Your Own Inventory',
  [HardwareProcurementType.Storefront]: 'Buy new hardware from Storefront',
};

const HARDWARE_PROCUREMENT_TITLES = {
  [HardwareProcurementType.New]: 'Buy new hardware',
  [HardwareProcurementType.Serial]: 'Add hardware from Electric inventory',
  [HardwareProcurementType.SerialInternal]:
    'Add hardware from your own inventory',
  [HardwareProcurementType.Storefront]: 'Buy new hardware from Storefront',
};
const PROVISIONING_LOCATION_TITLES = {
  [ProvisioningLocationTypes.AtElectric]: {
    value: ProvisioningLocationTypes.AtElectric,
    get label() {
      return i18n.t('xboarding:provisioningLocationTitles.atElectric', {
        defaultValue: 'Provision Hardware At Electric',
      });
    },
  },
  [ProvisioningLocationTypes.Remote]: {
    value: ProvisioningLocationTypes.Remote,
    get label() {
      return i18n.t('xboarding:provisioningLocationTitles.remote', {
        defaultValue: 'Provision Hardware Remotely',
      });
    },
  },
  [ProvisioningLocationTypes.DeviceHasAutoEnrollment]: {
    value: ProvisioningLocationTypes.DeviceHasAutoEnrollment,
    get label() {
      return i18n.t('xboarding:provisioningLocationTitles.autoEnrollment', {
        defaultValue: 'Provision Hardware with DEP',
      });
    },
  },
  [ProvisioningLocationTypes.NoProvisioningSupport]: {
    value: ProvisioningLocationTypes.NoProvisioningSupport,
    label: '',
  },
};

const PROVISIONING_LOCATION_OPTIONS = [
  {
    radioTestId: 'remote',
    toolTipTestId: 'tooltip-remote',
    label: 'Yes, Remote Provisioning',
    value: ProvisioningLocationTypes.Remote,
    tooltip:
      'Choose this selection if you’d like to schedule a time and date with one of Electric’s Service Technicians to remote into your computer and set up your device. This service requires someone to be available at your chosen date and time to work with an Electric Service Technician. Remote provisionings take approximately 1-3 hours total.',
    disabledTooltip: REMOTE_AVAILABILITY_MESSAGE,
  },
  {
    radioTestId: 'at-electric',
    toolTipTestId: 'tooltip-at-electric',
    label: 'Yes, At Electric',
    value: ProvisioningLocationTypes.AtElectric,
    tooltip:
      'Choose this selection if you would like for the device to be shipped to Electric’s headquarters to be set up by one of our Provisioning Specialists prior to being shipped to the destination of your choice. Turnaround time for this request is approximately 3 to 5 business days.',
    disabledTooltip: undefined,
  },
  {
    radioTestId: 'auto-enroll',
    toolTipTestId: 'tooltip-auto-enroll',
    label: 'This device has auto enrollment',
    value: ProvisioningLocationTypes.DeviceHasAutoEnrollment,
    tooltip:
      'Choose this selection if your company has a device auto-enrollment program set up with Apple. With auto-enrollment, there is no need for a separate provisioning session as the Device Enrollment Program (DEP) will do that for you. If the device is coming from Electric Inventory, it will require some provisioning support from Electric.',
    disabledTooltip: undefined,
  },
  {
    radioTestId: 'no-support',
    toolTipTestId: 'tooltip-no-support',
    label: 'No, I don’t need provisioning support',
    value: ProvisioningLocationTypes.NoProvisioningSupport,
    tooltip:
      'Choose this selection if you do not require device set up support from Electric for this onboarding. ',
    disabledTooltip: undefined,
  },
] as const;

export {
  HardwareProcurementType,
  HARDWARE_PROCUREMENT_LABELS,
  HARDWARE_PROCUREMENT_TITLES,
  ProvisioningLocationTypes,
  PROVISIONING_LOCATION_TITLES,
  PROVISIONING_LOCATION_OPTIONS,
};
