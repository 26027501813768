import { combineReducers } from '@reduxjs/toolkit';
import { reducer as openAddressPanels } from '@turbine/redux/openAddressPanels';
import { reducer as openDepartmentPanels } from '@turbine/redux/openDepartmentPanels';
import { reducer as formReducer } from 'redux-form';
import { customerEnrollment } from './customerEnrollmentReducer';
import { customerPlan } from './customerPlanReducer';
import { customerActivation } from './customerActivationReducer';
import customerSoftwares from '../customerSoftwares';
import customers from './customersReducer';
import dashboard from './dashboardReducer';
import deviceManagement from '../deviceManagement';
import { history } from './historyReducer';
import { modal } from './modalReducer';
import navItemNotificationsSlice from '../notifications/navItemNotificationsSlice';
import { policyId } from './policyIdReducer';
import policy from './policyReducer';
import appsTray from './trayReducer';
import { userPermissions } from './userPermissionsReducer';
import { userRoles } from '../userRoles/userRolesReducer';
import { users } from './usersReducer';
import { applicationBanners } from './applicationBannersReducer';
import newOffboarding from '../newOffboarding';
import newOnboarding from '../newOnboarding';
import people from '../people/peopleSlice';
import employees from '../employees';
import locations from '../locations';
import enrolledDevices from '../devices/enrolledDevices';
import storedDevices from '../devices/storedDevices';
import applications from '../applications';
import itScoreCard from '../itScoreCard';
import storefrontSlice from '../adhoc';

export const rootReducer = combineReducers({
  userPermissions,
  userRoles,
  policyId,
  policy,
  history,
  customers,
  users,
  openDepartmentPanels,
  openAddressPanels,
  dashboard,
  modal,
  customerSoftwares,
  customerPlan,
  customerEnrollment,
  form: formReducer,
  deviceManagement,
  navItemNotifications: navItemNotificationsSlice,
  applicationBanners,
  newOffboarding,
  newOnboarding,
  customerActivation,
  people,
  employees,
  locations,
  storedDevices,
  enrolledDevices,
  applications,
  appsTray,
  itScoreCard,
  storefrontSlice,
});

export default rootReducer;
