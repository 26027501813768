import type { StorefrontData } from '@electricjs/app-modules';
import { createSlice } from '@reduxjs/toolkit';

const initialState = null as StorefrontData | null;

export const storefrontSlice = createSlice({
  name: 'storefrontSlice',
  initialState,
  reducers: {
    storefrontDataReset: () => {
      return initialState;
    },
    storefrontDataSet: (state, action) => {
      const provisioningType = action.payload?.provisioningType;
      const storefrontCartData = action.payload?.carts;
      const officeLocation = action.payload?.officeLocation;
      const employee = action.payload?.employee;

      if (state) {
        state.provisioningType = provisioningType;
        state.carts = storefrontCartData;
        state.officeLocation = officeLocation;
        state.employee = employee;
      } else {
        state = {
          provisioningType,
          carts: storefrontCartData,
          officeLocation,
          employee,
        };
      }

      return state;
    },
  },
});

export const { storefrontDataReset, storefrontDataSet } =
  storefrontSlice.actions;
