import { type BannerIntent } from '@electricjs/arc/components/Banner/Banner';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { LOCK_STATUS } from '@turbine/constants/devices';
import { type Device } from '@turbine/types/DeviceManagement';

import {
  persistDeviceIdInURL,
  removeDeviceIdFromURL,
  getDeviceIDFromURL,
} from './enrolledDevicesSliceHelpers';
import {
  fetchDevicesByCustomerID,
  assignEmployeeToDevice,
  unAssignEmployeeFromDevice,
  lockWindowsDevice,
  lockMacDevice,
  unlockWindowsDevice,
} from './enrolledDevicesSliceThunks';

export type Toast = {
  intent: BannerIntent;
  title?: string;
  message?: string;
  stack?: boolean;
  hideAfter: number;
};

type DeviceState = {
  loading: boolean;
  data: Device[] | null;
  failed: boolean;
  assignmentLoading: boolean;
  assignmentFailed: boolean;
  unAssignmentLoading: boolean;
  unAssignmentFailed: boolean;
  lockWindowsLoading: boolean;
  lockWindowsFailed: boolean;
  lockMacLoading: boolean;
  lockMacFailed: boolean;
  unlockWindowsLoading: boolean;
  unlockWindowsFailed: boolean;
  selectedDeviceID: string | null;
  toast: Toast;
};

const initialToast = {
  intent: 'success' as BannerIntent,
  hideAfter: 5000,
};

const initialState: DeviceState = {
  loading: false,
  failed: false,
  data: null,
  assignmentFailed: false,
  assignmentLoading: false,
  unAssignmentFailed: false,
  unAssignmentLoading: false,
  lockWindowsLoading: false,
  lockWindowsFailed: false,
  lockMacLoading: false,
  lockMacFailed: false,
  unlockWindowsLoading: false,
  unlockWindowsFailed: false,
  selectedDeviceID: getDeviceIDFromURL(),
  toast: initialToast,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PayloadMetaAction = PayloadAction<any> & { meta: any };

export const devicesSlice = createSlice({
  name: 'enrolledDevices',
  initialState,
  reducers: {
    hideToast(state) {
      state.toast = initialToast;
    },
    assignLocalDevice(
      state,
      action: PayloadAction<{
        employeeID: string;
        deviceID: string | undefined;
      }>
    ) {
      const { employeeID, deviceID } = action.payload;
      const updatedDevices = state.data
        ? state.data.map(device =>
            device.id === deviceID
              ? {
                  ...device,
                  assigned_to: employeeID,
                }
              : device
          )
        : null;
      state.data = updatedDevices;
    },
    unAssignLocalDevice(
      state,
      action: PayloadAction<{ deviceID: string | undefined }>
    ) {
      const { deviceID } = action.payload;
      const updatedDevices = state.data
        ? state.data.map(device =>
            device.id === deviceID
              ? {
                  ...device,
                  assigned_to: null,
                }
              : device
          )
        : null;
      state.data = updatedDevices;
    },
    setLocalDevicePendingLockStatus(state, action: PayloadAction<string>) {
      const deviceID = action.payload;
      const updatedDevices = state.data
        ? state.data.map(device =>
            device.id === deviceID
              ? {
                  ...device,
                  lock_status: LOCK_STATUS.PENDING_LOCK,
                }
              : device
          )
        : null;
      state.data = updatedDevices;
    },
    setLocalDevicePendingUnlockStatus(state, action: PayloadAction<string>) {
      const deviceID = action.payload;
      const updatedDevices = state.data
        ? state.data.map(device =>
            device.id === deviceID
              ? {
                  ...device,
                  lock_status: LOCK_STATUS.PENDING_UNLOCK,
                }
              : device
          )
        : null;
      state.data = updatedDevices;
    },
    clickedDeviceDetailsForDeviceID: {
      reducer: (state, action: PayloadAction<string>) => {
        state.selectedDeviceID = action.payload;
      },
      prepare: (payload: string) => ({
        payload,
        meta: {
          updateUrl: () => persistDeviceIdInURL(payload),
        },
      }),
    },
    clickedHideDeviceDetailsForDeviceID: {
      reducer: state => {
        state.selectedDeviceID = null;
      },
      prepare: (payload?: never) => ({
        payload,
        meta: {
          updateUrl: removeDeviceIdFromURL,
        },
      }),
    },
    resetDevicesState: {
      reducer: () => ({ ...initialState, selectedDeviceID: null }),
      prepare: (payload?: never) => ({
        payload,
        meta: {
          updateUrl: removeDeviceIdFromURL,
        },
      }),
    },
  },
  // Curious about the below "builder" syntax / why these go in "extraReducers"?
  // Check out this documentation https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

  // The reducers property both creates an action creator function and responds to
  //  that action in the slice reducer.
  // The extraReducers allows you to respond to an action in your slice reducer but
  // does not create an action creator function.
  extraReducers: builder => {
    builder.addCase(fetchDevicesByCustomerID.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.failed = false;
    });
    builder.addCase(fetchDevicesByCustomerID.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchDevicesByCustomerID.rejected, state => {
      state.failed = true;
      state.loading = false;
      state.data = null;
      state.toast = {
        intent: 'error',
        title: 'Error!',
        message: 'Failed to fetch employees. Owner data will be unavailable',
        hideAfter: 5000,
      };
    });
    builder.addCase(assignEmployeeToDevice.fulfilled, (state, action) => {
      state.assignmentLoading = false;
      state.assignmentFailed = false;
      state.toast = {
        intent: 'success',
        title: 'Success!',
        message: `${action.meta.arg.ownerName} has been assigned to the device`,
        hideAfter: 5000,
      };
    });
    builder.addCase(assignEmployeeToDevice.pending, state => {
      state.assignmentLoading = true;
    });
    builder.addCase(
      assignEmployeeToDevice.rejected,
      (state, action: PayloadMetaAction) => {
        state.assignmentLoading = false;
        state.assignmentFailed = true;
        state.toast = {
          intent: 'error',
          title: 'Error!',
          message: `There was an error assigning ${action.meta?.arg?.ownerName} to the device`,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(unAssignEmployeeFromDevice.fulfilled, (state, action) => {
      state.unAssignmentLoading = false;
      state.unAssignmentFailed = false;
      state.toast = {
        intent: 'success',
        title: 'Success!',
        message: `${action.meta.arg.ownerName} has been unassigned from the device`,
        hideAfter: 5000,
      };
    });
    builder.addCase(unAssignEmployeeFromDevice.pending, state => {
      state.unAssignmentLoading = true;
    });
    builder.addCase(
      unAssignEmployeeFromDevice.rejected,
      (state, action: PayloadMetaAction) => {
        state.unAssignmentLoading = false;
        state.unAssignmentFailed = true;
        state.toast = {
          intent: 'error',
          title: 'Error!',
          message: `There was an error unassigning ${action.meta.arg.ownerName} from the device`,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(
      lockWindowsDevice.fulfilled,
      (state, action: PayloadMetaAction) => {
        state.lockWindowsLoading = false;
        state.lockWindowsFailed = false;
        state.toast = {
          intent: 'success',
          title: `${
            action.meta.arg.ownerName ? `${action.meta.arg.ownerName}'s ` : ''
          }Device lock Initiated`,
          message: 'This device will lock when it connects to the internet',
          stack: true,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(lockWindowsDevice.pending, state => {
      state.lockWindowsLoading = true;
    });
    builder.addCase(
      lockWindowsDevice.rejected,
      (state, action: PayloadMetaAction) => {
        state.lockWindowsLoading = false;
        state.lockWindowsFailed = true;
        state.toast = {
          intent: 'error',
          title: 'Device Lock Error',
          message: `We encountered an error when trying to lock ${
            action.meta.arg.ownerName ? `${action.meta.arg.ownerName}'s` : 'the'
          } device`,
          stack: true,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(
      lockMacDevice.fulfilled,
      (state, action: PayloadMetaAction) => {
        state.lockMacLoading = false;
        state.lockMacFailed = false;
        state.toast = {
          intent: 'success',
          title: `${
            action.meta.arg.ownerName ? `${action.meta.arg.ownerName}'s ` : ''
          }Device lock Initiated`,
          message: 'This device will lock when it connects to the internet',
          stack: true,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(lockMacDevice.pending, state => {
      state.lockMacLoading = true;
    });
    builder.addCase(
      lockMacDevice.rejected,
      (state, action: PayloadMetaAction) => {
        state.lockMacLoading = false;
        state.lockMacFailed = true;
        state.toast = {
          intent: 'error',
          title: 'Device Lock Error',
          message: `We encountered an error when trying to lock ${
            action.meta.arg.ownerName ? `${action.meta.arg.ownerName}'s` : 'the'
          } device`,
          stack: true,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(
      unlockWindowsDevice.fulfilled,
      (state, action: PayloadMetaAction) => {
        state.unlockWindowsLoading = false;
        state.unlockWindowsFailed = false;
        state.toast = {
          intent: 'success',
          title: `${
            action.meta.arg.ownerName ? `${action.meta.arg.ownerName}'s ` : ''
          }Device unlock Initiated`,
          message: 'This device will unlock when it connects to the internet',
          stack: true,
          hideAfter: 5000,
        };
      }
    );
    builder.addCase(unlockWindowsDevice.pending, state => {
      state.unlockWindowsLoading = true;
    });
    builder.addCase(
      unlockWindowsDevice.rejected,
      (state, action: PayloadMetaAction) => {
        state.unlockWindowsLoading = false;
        state.unlockWindowsFailed = true;
        state.toast = {
          intent: 'error',
          title: 'Device Unlock Error',
          message: `We encountered an error when trying to unlock ${
            action.meta.arg.ownerName ? `${action.meta.arg.ownerName}'s` : 'the'
          } device`,
          stack: true,
          hideAfter: 5000,
        };
      }
    );
  },
});

export const {
  assignLocalDevice,
  hideToast,
  unAssignLocalDevice,
  setLocalDevicePendingLockStatus,
  setLocalDevicePendingUnlockStatus,
  clickedDeviceDetailsForDeviceID,
  clickedHideDeviceDetailsForDeviceID,
  resetDevicesState,
} = devicesSlice.actions;
