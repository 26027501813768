import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { tryJSONParse } from '@turbine/helpers/tryJSONParse';
import {
  DEFAULT_SHIPPING_COUNTRY,
  type HardwareProcurementType,
  type ProvisioningLocationTypes,
} from '@turbine/lib/xboarding/constants';
import { isEqual, isUndefined, merge, unionWith } from 'lodash';
import { type Address } from './employeeInformationSlice';
import { type HardwareInfo } from '@turbine/graphql/types/boardingTypes';
import {
  type OnboardingDataProps,
  parseTaskListInformation,
} from '@turbine/pages/NewOnboarding/utils/parseOnBoardingData';
import { mergeReplacingArrays } from '@turbine/helpers/arrayHelpers';
import { fetchOnboardingDraft } from './draftOnboardingActions';
import { type SelectOption } from '@turbine/types/SelectOption';
import { type SoftwareMutationPayload } from '@turbine/graphql/types/onboardingMutations';
import { resetNewOnboarding } from './newOnboardingThunks';
import type { StorefrontData } from '@electricjs/app-modules';

export type CalendarPermissions = { id: string; name: string };

type CalendarsData = Array<{
  id: string;
  name: string;
  permission?: CalendarPermissions;
}>;

export type CalendarsDetails = {
  calendars?: Array<string>;
  calendarsData?: CalendarsData;
  permissions?: CalendarPermissions | null;
  notes?: string;
};

export enum ChatApplicationAccountType {
  Member = 'Member',
  MultiChannel = 'Multi-Channel Guest',
  SingleChannel = 'Single-Channel Guest',
  Guest = 'Guest',
}

export type ChatApplication = {
  applicationId: string;
  name: string;
  groups: string[];
  channels?: string[];
  accountType: ChatApplicationAccountType;
  notes?: string;
};

export type EmailGroupsDetails = {
  groups?: Array<string>;
  notes?: string;
};

export interface SoftwareNote {
  questionId: string;
  title: string;
  answer: string | SelectOption<string> | Array<SelectOption<string>>;
  children?: SoftwareNote[];
}
export interface Software {
  additionalNotes: string;
  id: string;
  name: string;
  notes: SoftwareNote[];
  requiresAccount: boolean;
  requiresInstallation: boolean;
}

export type SoftwareStatus = 'initial' | 'pending' | 'fulfilled';

export type SaasApps = {
  softwares: Record<string, Software>;
  status: SoftwareStatus;
};

export type HardwareState = {
  selectedHardware: HardwareInfo[];
  address: SelectOption<string | Address> | null;
  remoteAddress?: Address;
  shippingTo?: Address;
  provisioningType?: ProvisioningLocationTypes;
  procurementType?: HardwareProcurementType;
  notes?: string;
  storefrontCartData?: StorefrontData;
};

export type TaskList = {
  calendars: CalendarsDetails;
  chatApplication: ChatApplication;
  emailGroups: EmailGroupsDetails;
  saasApps: SaasApps;
  softwares?: SoftwareMutationPayload[];
  hardware: HardwareState;
  additionalInstructions?: string;
};

export type TaskListValues = {
  calendarAdditionalNotes: string;
  calendarPermissions: string;
  calendars: string[];
  calendarsData: CalendarsData;
  emailGroupsNotes: string;
  emailGroups: string[];
  chatApplication: ChatApplication;
  chatApplicationNotes: string;
  slackAccountType: string;
  slackChannels: string[];
  slackGroups: string[];
  softwares: SoftwareMutationPayload[];
  selectedHardware: HardwareInfo[];
  hardware: HardwareState;
  additionalInstructions: string;
};

export const defaultHardwareShippingAddress: Address = {
  city: '',
  country: DEFAULT_SHIPPING_COUNTRY,
  name: '',
  officeId: '',
  state: '',
  streetAddress1: '',
  streetAddress2: '',
  zip: '',
};

const initialState: TaskList = {
  hardware: {
    selectedHardware: [],
    address: null,
    shippingTo: defaultHardwareShippingAddress,
    notes: '',
    provisioningType: undefined,
  },
  calendars: {
    calendars: [],
    calendarsData: [],
    permissions: null,
    notes: '',
  },
  chatApplication: {
    applicationId: '',
    name: 'Slack',
    accountType: ChatApplicationAccountType.Member,
    groups: [],
    channels: [],
    notes: '',
  },
  emailGroups: {
    groups: [],
    notes: '',
  },
  saasApps: {
    status: 'initial',
    softwares: {} as SaasApps['softwares'],
  },
};

const taskList = createSlice({
  name: 'taskList',
  initialState,
  reducers: {
    hardwareSet: {
      reducer: (state, action: PayloadAction<HardwareState>) => {
        state.hardware = mergeReplacingArrays(state.hardware, action.payload);
      },
      prepare: (hardware?: HardwareState) => {
        if (!hardware) {
          return { payload: initialState.hardware };
        }
        return { payload: hardware };
      },
    },
    hardwareDevicesUpdated: (state, action: PayloadAction<HardwareInfo[]>) => {
      const mergedHardware = unionWith(
        state.hardware.selectedHardware,
        action.payload,
        isEqual
      );
      state.hardware.selectedHardware = mergedHardware;
    },
    calendarsUpdated: (
      state,
      action: PayloadAction<CalendarsDetails | undefined>
    ) => {
      if (!action.payload) return;
      const { permissions, calendarsData, ...restPayload } = action.payload;

      const calendarPermissions = isUndefined(permissions)
        ? state.calendars.permissions
        : tryJSONParse(permissions) || initialState.calendars.permissions;

      state.calendars = {
        ...state.calendars,
        ...restPayload,
        permissions: calendarPermissions,
        calendarsData: calendarsData?.map(calendarData => ({
          ...calendarData,
          permission: calendarPermissions,
        })),
      };
    },
    chatApplicationUpdated: (
      state,
      action: PayloadAction<ChatApplication | undefined>
    ) => {
      state.chatApplication = {
        ...state.chatApplication,
        ...action.payload,
      };
    },
    emailGroupsUpdated: (
      state,
      action: PayloadAction<EmailGroupsDetails | undefined>
    ) => {
      state.emailGroups = {
        ...state.emailGroups,
        ...action.payload,
      };
    },
    softwareAdded(
      state,
      { payload }: PayloadAction<{ id: string; software: Software }>
    ) {
      state.saasApps.softwares[payload.id] = payload.software;
    },
    softwaresUpdated(
      state,
      { payload }: PayloadAction<Record<string, Software | null> | undefined>
    ) {
      if (!payload) return;

      Object.entries(payload).forEach(([softwareId, software]) => {
        const currentSoftware = state.saasApps.softwares[softwareId];
        merge(currentSoftware, software);
      });
    },
    softwareUpdated(
      state,
      { payload }: PayloadAction<{ id: string; software: Partial<Software> }>
    ) {
      const currentSoftware = state.saasApps.softwares[payload.id];
      merge(currentSoftware, payload.software);
    },
    softwareRemoved(state, { payload }: PayloadAction<string>) {
      delete state.saasApps.softwares[payload];
    },
    softwareStatusUpdated(state, { payload }: PayloadAction<SoftwareStatus>) {
      state.saasApps.status = payload;

      if (['initial', 'pending'].includes(payload)) {
        state.saasApps.softwares = initialState.saasApps.softwares;
      }
    },
    saasAppsReset(state) {
      state.saasApps = initialState.saasApps;
    },
    resetTaskList: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(resetNewOnboarding, () => initialState);
    builder.addCase(
      fetchOnboardingDraft.fulfilled,
      (state, action: PayloadAction<OnboardingDataProps>) => {
        if (action?.payload) {
          const payload = action?.payload;
          const taskList = parseTaskListInformation(payload);
          return taskList;
        }
        return initialState;
      }
    );
  },
});

export const {
  calendarsUpdated,
  emailGroupsUpdated,
  softwareAdded,
  softwaresUpdated,
  softwareUpdated,
  softwareRemoved,
  softwareStatusUpdated,
  saasAppsReset,
  chatApplicationUpdated,
  hardwareDevicesUpdated,
  hardwareSet,
  resetTaskList,
} = taskList.actions;

export default taskList.reducer;
