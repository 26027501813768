/** Applications */
export const ALLOW_MULTIPLE_APPLICATION_ADMINS =
  'allow-multiple-application-admins1';
export const TRAY_ALPHA_INTEGRATION = 'trayio-alpha';
export const TURBINE_PRE_APPROVED_APPS = 'turbine-pre-approved-apps';

/** Common */
export const END_USER_ELLEN_TURBINE_ROLE = 'end-user-ellen-turbine-role';
export const GOD_MODE = 'turbine-god-mode';
export const TURBINE_SHOW_KUSTOMER_KNOWLEDGE_BASE_LINK =
  'turbine-show-kustomer-knowledge-base-link';
export const TURBINE_TEMPLATIZE_SAAS_APP_NOTES =
  'turbine-templatize-saas-app-notes';

/** Company Configuration */
export const SHOW_CHAT_APP_TEXT = 'turbine-show-chat-application-specific-text';
export const ADD_LOCATION_FORM_FIX_STATE =
  'turbine-frontend-add-location-form-fix-state-field';

/** Dashboard */
export const ELECTRIC_DEMO_DASHBOARD = 'turbine-electric-demo-dashboard';
export const ELECTRIC_HARDWARE_DEMO_DASHBOARD =
  'turbine-electric-hardware-demo-dashboard';
export const ELECTRIC_MALWAREBYTES_LIVE_DEMO_DASH =
  'turbine-live-malware-bytes-demo-dash';

/** Device Management */
export const TURBINE_COPY_DEVICE_MGMT_ROW = 'turbine-copy-device-mgmt-row';
export const TURBINE_SHOW_STATUS_STORED_DEVICES =
  'turbine-show-status-stored-devices';

/** Employees */
export const TURBINE_PEOPLE_USER_PROFILES = 'turbine-people-user-profiles';
export const TURBINE_PEOPLE_EDIT_USER_PROFILES =
  'turbine-people-edit-user-profiles';
export const TURBINE_PEOPLE_USER_PROFILES_SAAS_ADMIN_FIELD =
  'turbine-people-edit-user-profiles-saas-admin-field';
export const TURBINE_FRONTEND_PERSONAL_DEVICE =
  'turbine-frontend-personal-device';

/** Storefront */
export const VAR_GA_STOREFRONT = 'var-ga-storefront';
export const VAR_GA_STOREFRONT_CUSTOM_HARDWARE_BUTTON =
  'var-ga-storefront-custom-hardware-button';
export const VAR_GA_STOREFRONT_CHOOSE_OWN_INVENTORY =
  'var-ga-storefront-choose-own-inventory';
export const TURBINE_STOREFRONT_COMPANY_CONFIG_HARDWARE =
  'turbine-storefront-company-config-hardware';
export const VAR_GA_STOREFRONT_CHOOSE_FROM_ELECTRIC_INVENTORY =
  'var-ga-storefront-choose-from-electric-inventory';
export const VAR_GA_STOREFRONT_PROVISIONING_SELECTION =
  'var-ga-storefront-provisioning-selection';
export const VAR_GA_STOREFRONT_NEW_HWL_ADDRESS = 'storefront-new-hwl-address';

/** Turbine adhoc requests */
export const TURBINE_ADHOC_REQUESTS = 'turbine-ad-hoc-requests';

/** Turbine Provisioning */
export const TURBINE_SMART_PROVISIONING = 'turbine-smart-provisioning';

/** Apple ZTP */
export const TURBINE_FRONTEND_APPLE_ZTP =
  'turbine-frontend-apple-ztp-eligibility';

/** Google Search Address */
export const TURBINE_FRONTEND_GOOGLE_SEARCH_ADDRESS =
  'turbine-frontend-google-search-address';

/** IT Scorecard */
export const TURBINE_G_SUITE_ELECTRIC_ASSISTANT_O_AUTH_ROLE =
  'turbine-g-suite-electric-assistant-o-auth-role';
export const TURBINE_HIDE_IT_SCORECARD_SAAS_MODULES =
  'turbine-hide-it-scorecard-saas-modules';
export const TURBINE_IT_SCORE_FIREWALL_REQUESTS =
  'turbine-it-score-firewall-requests';

/** Hardware Data */

export const TURBINE_FRONTEND_HARDWARE_DATA = 'turbine-frontend-hardware-data';
/** xBoardings */
export const ELECTRICAI_XBOARDING_RESTRICTED =
  'electricai-xboarding-restricted';
export const TURBINE_FRONTEND_ALLOW_ONBOARDING_MARK_AS_DONE =
  'turbine-frontend-allow-onboarding-mark-as-done';
export const TURBINE_FRONTEND_ALLOW_OFFBOARDING_MARK_AS_DONE =
  'turbine-frontend-allow-offboarding-mark-as-done';
export const ZOOM_OFFBOARDING_AUTOMATION = 'zoom-offboarding-automation';
export const TURBINE_OFFBOARDING_PREFILL = 'turbine-offboarding-prefill';
export const TURBINE_ONBOARDING_UNIQUE_EMAIL_VALIDATION =
  'turbine-onboarding-unique-emails-validation';
export const GOOGLE_OFFBOARDING_AUTOMATION_V2 =
  'google-offboarding-automation-v2';

/** Config */
export const TRANSCRIPT_OPEN_REQUEST = 'turbine-transcript-open-requests';
export const TURBINE_APPROVALS_PAGE = 'turbine-approvals-page';
export const TURBINE_MARKETPLACE = 'turbine-marketplace';
export const TURBINE_SELF_SERVICE_CUSTOMER = 'turbine-self-service-customer';
export const TURBINE_SHOW_SELF_SIGNUP_PAGE = 'turbine-show-self-signup-page';
export const TURBINE_SELF_SIGNUP_EMAIL_SETUP =
  'turbine-self-signup-email-setup';

/** reporting */
export const TURBINE_REPORTS_PAGE_MALWAREBYTES =
  'turbine-reports-page-malwarebytes';
export const TURBINE_REPORTS_PAGE_XBOARDING = 'turbine-reports-page-x-boarding';
export const TURBINE_REPORTS_ANNUAL_SUMMARY = 'turbine-reports-annual-summary';
export const TURBINE_REPORTS_SUPPORTED_EMPLOYEES =
  'turbine-reports-supported-employees';

const FEATURE_FLAG_CONFIG = {
  '/request/:id': {
    feature: TRANSCRIPT_OPEN_REQUEST,
    value: true,
  },
  '/marketplace': {
    feature: TURBINE_MARKETPLACE,
    value: true,
  },
  '/approvals': {
    feature: TURBINE_APPROVALS_PAGE,
    value: true,
  },
};

export default FEATURE_FLAG_CONFIG;
