import { memo, useState } from 'react';
import styled from 'styled-components';
import {
  Pane,
  IconButton,
  Button,
  Skeleton,
  getToken,
  ColorsV2,
  Menu,
  MenuItem,
  MenuSeparator,
} from '@electricjs/arc';
import NavLogo from '../SideNav/NavLogo';
import { useBreakpoint, useFeatureFlag } from '@electricjs/utils/hooks';
import { useSession } from '@turbine/helpers/hooks';
import SwitchCompanyModal from './SwitchCompanyModal';
import SideNavItem from '../SideNav/SideNavItem';
import { useCustomer } from '@turbine/helpers/hooks/useCustomer';
import { useFullPageLayout } from '@turbine/helpers/hooks/useFullPageLayout';
import addRelicxSessionVars from '@turbine/services/relicx/addRelicxSessionVars';
import { END_USER_ELLEN_TURBINE_ROLE } from '@turbine/constants/featureFlags';
import { areUserPermissionsEmpty } from '@turbine/redux/selectors/userPermissionsSelector';
import { customerEnrollmentSelector } from '@turbine/redux/selectors/customerEnrollmentSelector';
import { selectHasChatAppState } from '@turbine/redux/applications';
import { REACT_APP_END_USER_APPLICATION_URL } from '@turbine/config';
import { PLANS } from '@turbine/constants/customerPlans';
import { NAV_HEIGHT } from '@turbine/constants/sizes';
import { useAppSelector } from '@turbine/redux/store';
import { isPremiumPlanSelector } from '@turbine/redux/selectors/customerPlanSelector';

const StyledTopNav = styled(Pane)`
  z-index: 2;
  height: ${NAV_HEIGHT};
  min-height: ${NAV_HEIGHT};
  padding: 0 3.5rem;
  border-radius: unset;

  /** If the page is above mobile size AND not the full page view,
   * the side nav will be visible with the logo,
   * else the logo will be in the top nav and need to be spaced.
   */
  justify-content: ${props =>
    props.$md &&
    !props.$isFullPageLayout &&
    props.$enrollmentCompleted &&
    !props.$isNavLoading
      ? 'flex-end'
      : 'space-between'};

  background: ${getToken(ColorsV2.BACKGROUND)};
  border: solid 1px ${getToken(ColorsV2.GRAY_LIGHT)};
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0 1.5rem 0 2.5rem;
  color: ${getToken(ColorsV2.TEXT)};
  height: 3rem;
  span {
    font-weight: 400;
  }
  &:hover {
    color: ${getToken(ColorsV2.PRIMARY)};
    background-color: ${getToken(ColorsV2.BACKGROUND_ALT)};
  }
`;

// Used to group the right side nav buttons for the parent flex container
const StyledNavButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type TopNavProps = {
  onMenuClick: () => void;
  enrollmentCompleted?: boolean;
};

const TopNav = memo(({ onMenuClick, enrollmentCompleted }: TopNavProps) => {
  const { logout, isAdmin } = useSession();
  const { name, plan } = useCustomer();
  // Now that the customer has been identified, send in the tenant as a session variable for RelicX
  if (name) {
    addRelicxSessionVars({ tenant: name });
  }
  const { md } = useBreakpoint();
  const { loaded: enrollmentLoaded } = useAppSelector(
    customerEnrollmentSelector
  );
  const { hasChatApp } = useAppSelector(selectHasChatAppState);
  const isNavLoading =
    useAppSelector(areUserPermissionsEmpty) || !enrollmentLoaded;
  const isFullPageLayout = useFullPageLayout();
  const [showSwitchCompanyModal, setSwitchCompanyModal] = useState(false);
  const featureFlagEUHLink = useFeatureFlag(END_USER_ELLEN_TURBINE_ROLE);
  const plansWithEUHAccess = [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM];
  const showEndUserHubLink =
    REACT_APP_END_USER_APPLICATION_URL != null &&
    featureFlagEUHLink &&
    Object.values(plansWithEUHAccess).includes(plan);
  const isPremiumPlan = useAppSelector(isPremiumPlanSelector);

  const onKnowledgeBaseClick = () => {
    const newWindow = window.open('https://support.electric.ai', '_blank');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      {/* Show basic nav while nav states are loading */}
      {isNavLoading ? (
        <StyledTopNav
          withPadding
          vertical="center"
          testId="topnav"
          $md={md}
          $isFullPageLayout={isFullPageLayout}
          $enrollmentCompleted={enrollmentCompleted}
          $isNavLoading={isNavLoading}>
          <NavLogo logoLink="/" />
          <Skeleton
            circle={!md}
            count={1}
            width={!md ? '4rem' : '14.5rem'}
            height={!md ? '4rem' : '3.4rem'}
          />
        </StyledTopNav>
      ) : (
        <StyledTopNav
          withPadding
          vertical="center"
          testId="topnav"
          $md={md}
          $isFullPageLayout={isFullPageLayout}
          $enrollmentCompleted={enrollmentCompleted}>
          {!md && (
            <>
              <IconButton
                testId="hamburger-button"
                icon="menu"
                onClick={onMenuClick}
                intent="neutral"
              />
              <NavLogo logoLink="/" />
            </>
          )}
          {/* If screen size is mobile, or if it's desktop/tablet and either the full page layout or enrollment, then show the logo */}
          {!md ||
            (md && (isFullPageLayout || !enrollmentCompleted) && (
              <NavLogo logoLink="/" />
            ))}
          <StyledNavButtonContainer>
            {md ? (
              <Button
                testId="user-menu-knowledge"
                onClick={onKnowledgeBaseClick}
                variant="text"
                intent="neutral"
                iconBefore="question-circle">
                Knowledge Base
              </Button>
            ) : (
              <div style={{ marginRight: '1rem' }}>
                <IconButton
                  icon="question-circle"
                  onClick={onKnowledgeBaseClick}
                  testId="user-menu-knowledge"
                  intent="primary"
                />
              </div>
            )}

            <>
              <Menu
                placement="bottom-start"
                trigger={
                  !name ? (
                    <Skeleton
                      circle={!md}
                      count={1}
                      width={!md ? '4rem' : '14.5rem'}
                      height={!md ? '4rem' : '3.4rem'}
                    />
                  ) : md ? (
                    <Button
                      testId="user-menu-button"
                      variant="text"
                      intent="neutral"
                      iconBefore="building">
                      {name}
                    </Button>
                  ) : (
                    <IconButton
                      icon="building"
                      onClick={onKnowledgeBaseClick}
                      testId="user-menu-knowledge"
                      intent="primary"
                    />
                  )
                }>
                <>
                  {(enrollmentCompleted || !hasChatApp) && (
                    <>
                      {!isPremiumPlan && (
                        <SideNavItem
                          itemType="Configuration"
                          isLoading={isNavLoading}
                          isInsideDropdown={true}
                        />
                      )}
                      <SideNavItem
                        itemType="RoleSettings"
                        isLoading={isNavLoading}
                        isInsideDropdown={true}
                      />
                      {!isPremiumPlan && (
                        <SideNavItem
                          itemType="Locations"
                          isLoading={isNavLoading}
                          isInsideDropdown={true}
                        />
                      )}
                    </>
                  )}
                  {isAdmin && (
                    <StyledMenuItem onClick={() => setSwitchCompanyModal(true)}>
                      Switch Company
                    </StyledMenuItem>
                  )}
                  {showEndUserHubLink && (
                    <StyledMenuItem
                      onClick={() =>
                        window.location.replace(
                          `${REACT_APP_END_USER_APPLICATION_URL}/api/auth/login`
                        )
                      }>
                      Go to Electric User Hub
                    </StyledMenuItem>
                  )}
                  <MenuSeparator />
                  <StyledMenuItem onClick={logout} iconAfter={'logout'}>
                    Log out
                  </StyledMenuItem>
                </>
              </Menu>
            </>
          </StyledNavButtonContainer>
        </StyledTopNav>
      )}
      <SwitchCompanyModal
        isOpen={showSwitchCompanyModal}
        onClose={() => setSwitchCompanyModal(false)}
      />
    </>
  );
});

export default TopNav;
