import { createSelector } from '@reduxjs/toolkit';
import {
  getYear,
  getHours,
  getMinutes,
  getMonth,
  getDate,
  parseISO,
} from 'date-fns';
import isValid from 'date-fns/isValid';
import { get, isString } from 'lodash/fp';
import compact from 'lodash/compact';
import { tryJSONParse } from '@turbine/helpers/tryJSONParse';
import { type HardwareInfo } from '@turbine/graphql/types/boardingTypes';
import { type RootState } from '../store';
import { type EmployeeInformation } from './employeeInformationSlice';
import {
  type HardwareState,
  type SaasApps,
  type SoftwareNote,
  type TaskList,
} from './taskListSlice';
import {
  type SoftwareMutationPayload,
  type SoftwareNoteMutationPayload,
} from '@turbine/graphql/types/onboardingMutations';
import { REMOTE_ADDRESS_OPTION } from '@turbine/lib/addresses/constants';
import {
  HardwareProcurementType,
  type ProvisioningLocationTypes,
} from '@turbine/lib/xboarding/constants';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getSafeDate } from '@turbine/lib/xboarding/utils';
import { type NewOnboardingState } from './formStateSlice';

/** Employee Information Selectors */
const getEmployeeInformation = createSelector(
  get('newOnboarding.employeeInformation'),
  fields => fields
);

export const employeeInformationSelector = createSelector<
  any,
  EmployeeInformation,
  EmployeeInformation
>(getEmployeeInformation, data => data);

export type EmployeeInfoFields = keyof EmployeeInformation;

const getEmployeeField = <T extends EmployeeInfoFields>(field: T) =>
  createSelector(
    get(['newOnboarding', 'employeeInformation', field]),
    (fields: EmployeeInformation[T]) => fields
  );

export const employeeInfoValueSelector = <T extends EmployeeInfoFields>(
  field: T
) => createSelector(getEmployeeField(field), value => value);

export const getEmployeeName = (state: RootState) => ({
  firstName: getEmployeeField('firstName')(state),
  lastName: getEmployeeField('lastName')(state),
});

export const getJobTitle = (state: RootState) =>
  getEmployeeField('jobTitle')(state);

export const getManager = (state: RootState) =>
  getEmployeeField('manager')(state);

const getWelcomeEmail = (state: RootState) =>
  getEmployeeField('welcomeEmail')(state);

const getPersonalEmail = (state: RootState) => getWelcomeEmail(state)?.email;

const welcomeEmailTimeSelector = createSelector(
  getWelcomeEmail,
  welcomeEmail => {
    const time = getSafeDate(welcomeEmail?.time?.value);
    return time;
  }
);

const welcomeEmailDateSelector = createSelector(
  getWelcomeEmail,
  welcomeEmailTimeSelector,
  (welcomeEmail, welcomeEmailTime) => {
    const { date, timeZone } = welcomeEmail || {};
    let hours = 0;
    let minutes = 0;
    const seconds = 0;

    if (!date || !welcomeEmailTime || !timeZone) return undefined;

    const welcomeDate = isString(date) ? parseISO(date) : date;

    if (isValid(welcomeEmailTime)) {
      hours = getHours(welcomeEmailTime);
      minutes = getMinutes(welcomeEmailTime);
    }

    const year = getYear(welcomeDate);
    const month = getMonth(welcomeDate);
    const dateOfMonth = getDate(welcomeDate);

    const dateForUTC = new Date(
      year,
      month,
      dateOfMonth,
      hours,
      minutes,
      seconds
    );

    const utcDate = zonedTimeToUtc(dateForUTC, timeZone.value);
    const welcomeEmailDate = date ? utcDate : undefined;
    return welcomeEmailDate;
  }
);

const getWelcomeEmailTimezone = (state: RootState) =>
  getWelcomeEmail(state)?.timeZone?.value;

const getEmailFormats = (state: RootState) => {
  const professionalEmail = getEmployeeField('professionalEmail')(state);
  return [professionalEmail?.value];
};

const getEmailFormatsNotes = (state: RootState) => {
  const emailFormatsNotes = getEmployeeField('emailFormatsNotes')(state);
  return emailFormatsNotes;
};

const addressSelector = createSelector(
  getEmployeeInformation,
  employeeInfo => tryJSONParse(employeeInfo.address)?.value
);

const remoteAddressSelector = createSelector(
  getEmployeeInformation,
  employeeInfo => employeeInfo.remoteAddress
);

const locationAddressSelector = createSelector(
  addressSelector,
  remoteAddressSelector,
  (address, remoteAddress) =>
    address === REMOTE_ADDRESS_OPTION.value ? remoteAddress : address
);

const getDepartment = (state: RootState) =>
  getEmployeeField('department')(state)?.value;

const getEmploymentType = (state: RootState) =>
  getEmployeeField('employmentType')(state);

const getNotifyWhenOnboardingComplete = (state: RootState) =>
  getEmployeeField('notifyWhenOnboardingComplete')(state);

const getLastName = (state: RootState) => getEmployeeField('lastName')(state);

const getFirstName = (state: RootState) => getEmployeeField('firstName')(state);

export const getFullName = (state: RootState) =>
  `${getFirstName(state)} ${getLastName(state)}`;

const getStartDate = (state: RootState) =>
  getEmployeeField('scheduledAt')(state);

export const getEmailAlreadyExists = (state: RootState) =>
  getEmployeeField('emailAlreadyExists')(state);

export const getAllowSubmitExistingEmail = (state: RootState) =>
  getEmployeeField('allowSubmitExistingEmail')(state);

const employeeInfoSelector = createSelector(
  [
    locationAddressSelector,
    getDepartment,
    getEmailFormats,
    getEmailFormatsNotes,
    getEmploymentType,
    getFirstName,
    getLastName,
    getPersonalEmail,
    getStartDate,
    welcomeEmailDateSelector,
    getWelcomeEmailTimezone,
    getNotifyWhenOnboardingComplete,
    getJobTitle,
    getManager,
  ],
  (
    address,
    department,
    emailFormats,
    emailFormatsNotes,
    employmentType,
    firstName,
    lastName,
    personalEmail,
    startDate,
    welcomeEmailDate,
    welcomeEmailTimezone,
    notifyWhenOnboardingComplete,
    jobTitle,
    manager
  ) => ({
    address,
    department,
    emailFormats,
    emailFormatsNotes,
    employmentType,
    firstName,
    lastName,
    personalEmail,
    startDate,
    welcomeEmail: {
      email: personalEmail,
      emailDate: welcomeEmailDate,
      timezone: welcomeEmailTimezone,
    },
    notifyWhenOnboardingComplete,
    jobTitle,
    managerId: manager?.id,
  })
);

/** Task List Selectors */
export type TaskListField = keyof TaskList;

export const getTaskList = createSelector<any, TaskList, TaskList>(
  get('newOnboarding.taskList'),
  fields => fields
);

export const taskListSelector = createSelector<any, TaskList, TaskList>(
  getTaskList,
  data => data
);

const getTaskListField = <T extends TaskListField>(field: T) =>
  createSelector(
    get(['newOnboarding', 'taskList', field]),
    (fields: TaskList[T]) => fields
  );

export const taskListFieldValueSelector = <T extends TaskListField>(field: T) =>
  createSelector(getTaskListField(field), value => value);

export const selectNewOnboardingSoftwares = (state: RootState) =>
  getTaskListField('saasApps')(state)?.softwares;

export const selectNewOnboardingSoftwareStatus = (state: RootState) =>
  getTaskListField('saasApps')(state)?.status;

export const selectNewOnboardingIsDuplicating = (state: RootState) =>
  state.newOnboarding.formState.isDuplicating;

export const selectIsOnboardingDraftCreated = (state: RootState) =>
  state.newOnboarding.formState.isDraftCreated;

export const selectOnboardingDraftId = (state: RootState) =>
  state.newOnboarding.formState.currentDraftId;

export const selectCurrentStep = (state: RootState) =>
  state.newOnboarding.formState.currentStep;

export const selectStepProgress = (state: RootState) =>
  state.newOnboarding.formState.stepProgress;

export const selectDraftStepProgress = (state: RootState) =>
  state.newOnboarding.formState.draftStepProgress;

export const selectIsDraftStatus =
  (status: NewOnboardingState['status']) =>
  (state: RootState): boolean =>
    state.newOnboarding.formState.status === status;

export const selectIsCurrentStepCompleted = createSelector(
  selectCurrentStep,
  selectStepProgress,
  (currentStep, stepProgress) => currentStep <= stepProgress
);

export const selectIsDraftUnsaved = (state: RootState) =>
  state.newOnboarding.formState.isUnsaved;

const getHardwareInfo = (state: RootState) =>
  getTaskListField('hardware')(state);

const getProvisioningType = (state: RootState) =>
  getHardwareInfo(state).provisioningType;

const getAdditionalInstructions = (state: RootState) =>
  getHardwareInfo(state).notes;

export const mapHardwareInfo = (
  provisioningType: ProvisioningLocationTypes | undefined,
  hardwareInfo?: HardwareState
) => {
  const { shippingTo: remoteShippingAddress, address } = hardwareInfo || {};
  const isRemote = address?.value === REMOTE_ADDRESS_OPTION.value;
  const hardwares = hardwareInfo?.selectedHardware?.map(
    (hardware: HardwareInfo) => {
      const link =
        hardware?.procurementType === HardwareProcurementType.Serial
          ? ''
          : hardware?.link;

      let shippingTo = undefined;

      if (isRemote) {
        shippingTo = tryJSONParse(remoteShippingAddress);
      } else {
        shippingTo = tryJSONParse(address?.value);
        if (typeof shippingTo === 'object') {
          shippingTo = {
            ...shippingTo,
            phone: shippingTo.phone || shippingTo.officePhone,
          };
        }
      }

      return {
        ...hardware,
        link,
        shippingTo,
        provisioningType,
      };
    }
  );
  return hardwares;
};

const selectedHardwareSelector = createSelector(
  [getHardwareInfo, getProvisioningType],
  (hardwareInfo, provisioningType) =>
    mapHardwareInfo(provisioningType, hardwareInfo)
);

const getCalendarsObject = (state: RootState) =>
  getTaskListField('calendars')(state);

const getCalendars = (state: RootState) => getCalendarsObject(state).calendars;

const getCalendarPermissions = (state: RootState) =>
  getCalendarsObject(state).permissions?.name;

const getCalendarAdditionalNotes = (state: RootState) =>
  getCalendarsObject(state).notes;

const getCalendarsData = (state: RootState) =>
  getCalendarsObject(state).calendarsData;

const getChatApplication = (state: RootState) =>
  getTaskListField('chatApplication')(state);

const getChatAppChannels = (state: RootState) =>
  getChatApplication(state).channels;

const getChatAppGroups = (state: RootState) => getChatApplication(state).groups;

const getChatAppAccountType = (state: RootState) =>
  getChatApplication(state).accountType;

const getChatAppNotes = (state: RootState) => getChatApplication(state).notes;

const chatAppInfoSelector = createSelector(
  [
    getChatAppChannels,
    getChatAppGroups,
    getChatAppAccountType,
    getChatAppNotes,
  ],
  (channels, groups, accountType, notes) => ({
    channels: channels || [],
    groups: groups || [],
    accountType,
    notes,
  })
);

const getEmailGroupsObject = (state: RootState) =>
  getTaskListField('emailGroups')(state);

const getEmailGroups = (state: RootState) => getEmailGroupsObject(state).groups;

const getEmailGroupsNotes = (state: RootState) =>
  getEmailGroupsObject(state).notes;

/**
 * SOFTWARES
 * https://electricops.atlassian.net/browse/IA1-2774
 * TODO move to its own file
 */

const saasAppsSelector = createSelector(
  getTaskList,
  taskListInfo => taskListInfo.saasApps
);

const mapSoftwareNotes = ({
  answer,
  children,
  questionId,
  title,
}: SoftwareNote): SoftwareNoteMutationPayload => {
  const normalizeAnswer = (
    answer: SoftwareNote['answer']
  ): SoftwareNoteMutationPayload['answer'] => {
    if (Array.isArray(answer)) {
      return compact(answer.map(normalizeAnswer)) as string[];
    }

    if (typeof answer === 'string') {
      return answer;
    }

    if (answer?.label) {
      return answer?.label;
    }

    return '';
  };

  return {
    title,
    question_id: questionId,
    answer: normalizeAnswer(answer),
    children: children?.map(mapSoftwareNotes),
  };
};

export const mapSoftwares = (saasAppsInfo: SaasApps | undefined) => {
  if (!saasAppsInfo) return [];

  const saasAppIds = Object.keys(saasAppsInfo?.softwares);
  const softwares: SoftwareMutationPayload[] = [];
  saasAppIds.forEach(id => {
    const {
      name,
      requiresAccount,
      requiresInstallation,
      additionalNotes,
      notes,
    } = saasAppsInfo.softwares[id];
    // map keys to match submit payload
    softwares.push({
      id,
      name,
      additional_notes: additionalNotes,
      requires_account: requiresAccount,
      requires_installation: requiresInstallation,
      notes: notes.map(mapSoftwareNotes),
    });
  });

  return softwares;
};

const softwaresSelector = createSelector(saasAppsSelector, saasAppsInfo => {
  const softwares = mapSoftwares(saasAppsInfo);
  return softwares;
});

const getRequestTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getRequestDateTime = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

const taskListInfoSelector = createSelector(
  [
    getAdditionalInstructions,
    getCalendarAdditionalNotes,
    getCalendarPermissions,
    getCalendars,
    getCalendarsData,
    getEmailGroupsNotes,
    getEmailGroups,
    chatAppInfoSelector,
    selectedHardwareSelector,
    softwaresSelector,
  ],
  (
    additionalInstructions,
    calendarAdditionalNotes,
    calendarPermissions,
    calendars,
    calendarsData,
    emailGroupsNotes,
    emailGroups,
    chatApplication,
    selectedHardware,
    softwares
  ) => ({
    additionalInstructions,
    calendarAdditionalNotes,
    calendarPermissions,
    calendars,
    calendarsData,
    emailGroupsNotes,
    emailGroups,
    selectedHardware,
    slackAccountType: chatApplication.accountType,
    slackChannels: chatApplication.channels,
    slackGroups: chatApplication.groups,
    chatApplicationNotes: chatApplication.notes,
    softwares,
  })
);

export const selectNewOnboardingInfo = createSelector(
  employeeInfoSelector,
  taskListInfoSelector,
  getRequestTimezone,
  selectDraftStepProgress,
  (employeeInfo, taskListInfo, requestTimezone, draftStepProgress) => ({
    ...employeeInfo,
    ...taskListInfo,
    requestTimezone,
    get requestDateTime(): string {
      return getRequestDateTime();
    },
    draftStepProgress,
  })
);

export const selectNewOnboardingValidationFailed = (state: RootState) =>
  state.newOnboarding.formState.hasValidationErrors;
