import {
  createContext,
  useState,
  useEffect,
  useContext,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from 'react';
import createAuth0Client, {
  type Auth0Client,
  type IdToken,
  type RedirectLoginOptions,
  type User,
} from '@auth0/auth0-spa-js';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { browserHistory as history } from '@electricjs/utils/helpers';
import {
  auth as AUTH_CONFIG,
  auth0LogoutUrl,
  REACT_APP_END_USER_ROUTING,
  REACT_APP_END_USER_APPLICATION_URL,
} from '@turbine/config';
import { REDIRECTS } from '@turbine/routes/redirectsConfig';
import { userPermissionsSet } from '@turbine/redux/actions/userPermissionsActions';
import { Roles } from '@turbine/constants/roles';
import { type AppDispatch } from '@turbine/redux/store';

const invalidURLS = [auth0LogoutUrl, AUTH_CONFIG.redirectUri];
const redirectUriIsInvalid = (targetPath: string) =>
  invalidURLS.some(url => url?.includes(targetPath));

const onRedirectCallback = (targetPath: string) => {
  const hashParams = queryString.parse(window.location.hash);
  const scoreCardRoute = `${REDIRECTS.TO.IT_SCORECARD}#${queryString.stringify(
    hashParams
  )}`;
  if (!targetPath) return history.replace(scoreCardRoute);
  return history.replace(
    redirectUriIsInvalid(targetPath) ? scoreCardRoute : targetPath
  );
};

type Session = {
  user: (User & IdToken) | undefined;
  userRoles: string[];
  isAdmin: boolean;
  isCustomerAdmin: boolean;
  isCustomerSuperAdmin: boolean;
  userId: string;
  customerId: string;
  originalCustomerId: string;
  token: string;
  idToken: string | null;
  isEndUser: boolean;
};

export type AuthContextProps = {
  isAuthenticated: boolean;
  loading: boolean;
  getIdTokenClaims: () =>
    | ReturnType<Auth0Client['getIdTokenClaims']>
    | undefined;
  loginWithRedirect: (
    appState?: RedirectLoginOptions
  ) => ReturnType<Auth0Client['loginWithRedirect']> | undefined;
  getTokenSilently: () =>
    | ReturnType<Auth0Client['getTokenSilently']>
    | undefined;
  logout: () => ReturnType<Auth0Client['logout']> | undefined;
  setCustomerId: Dispatch<SetStateAction<undefined | string>>;
} & Session;

export const Auth0Context = createContext<AuthContextProps | undefined>(
  undefined
);

export type UseSessionReturnType = ReturnType<typeof useSession>;

export function useSession() {
  const context = useContext(Auth0Context);
  if (context === undefined) {
    throw new Error('useSession must be inside a Provider with a value');
  }
  return context;
}

const CUSTOMER_ID_PATH = 'https://app.electric.ai/turbine/customer_id';
const USER_ID_PATH = 'https://app.electric.ai/turbine/id';
const USER_ROLES_PATH = 'https://app.electric.ai/turbine/roles';
const USER_PERMISSIONS_PATH = 'https://app.electric.ai/turbine/permissions';

async function initializeSession(
  auth0FromHook: Auth0Client,
  setSession: (session: Session) => void,
  dispatch: AppDispatch
) {
  const user = await auth0FromHook.getUser();
  const endUserRoutingEnabled =
    String(REACT_APP_END_USER_ROUTING) === 'true' &&
    !!REACT_APP_END_USER_APPLICATION_URL;
  // If we are dealing with an end user, and the flag is on, redirect them.
  const isEndUser =
    user?.[USER_ROLES_PATH].length > 0 &&
    user?.[USER_ROLES_PATH].every((role: unknown) => role === Roles.HubUser);
  if (endUserRoutingEnabled && isEndUser) {
    window.location.replace(
      `${REACT_APP_END_USER_APPLICATION_URL}/api/auth/login`
    );
    return;
  }
  const isAdmin =
    user?.[USER_ROLES_PATH] &&
    user?.[USER_ROLES_PATH].includes(Roles.ElectricAdmin);
  const isCustomerAdmin =
    isAdmin || user?.[USER_ROLES_PATH]?.includes(Roles.Admin);
  const isCustomerSuperAdmin = user?.[USER_ROLES_PATH]?.includes(
    Roles.SuperAdmin
  );
  const customerIdQueryString = queryString.parse(window.location.hash, {
    arrayFormat: 'comma',
  }).customerId;
  const customerId =
    (isAdmin && customerIdQueryString) || user?.[CUSTOMER_ID_PATH] || '';
  const originalCustomerId = customerId;
  const userId = user?.[USER_ID_PATH] || '';
  const userRoles = user?.[USER_ROLES_PATH] || [];
  const claims = await auth0FromHook.getIdTokenClaims();
  const token = await auth0FromHook.getTokenSilently();
  const idToken = claims?.__raw || null;

  const session: Session = {
    user: {
      // todo: inspect difference between user and claims and use 1 or cherrypick key/values
      ...user,
      ...claims,
    },
    userRoles,
    isAdmin,
    isCustomerAdmin,
    isCustomerSuperAdmin: isCustomerSuperAdmin,
    userId,
    customerId,
    originalCustomerId,
    token,
    idToken,
    isEndUser,
  };

  dispatch(userPermissionsSet(session?.user?.[USER_PERMISSIONS_PATH]));

  setSession(session);
}

type Auth0ProviderProps = { children: ReactNode };

export const Auth0Provider = ({ children }: Auth0ProviderProps) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [session, setSession] = useState<Session>({
    user: undefined,
    userRoles: [],
    isAdmin: false,
    isCustomerAdmin: false,
    isCustomerSuperAdmin: false,
    userId: '',
    customerId: '',
    originalCustomerId: '',
    token: '',
    idToken: null,
    isEndUser: false,
  });
  const [auth0Client, setAuth0] = useState<Auth0Client | null>(null);
  const [loading, setLoading] = useState(true);
  const [customerId, setCustomerId] = useState<undefined | string>();

  /**
   * When 'localstorage' is used as a cacheLocation value
   * (intended for Cypress login command),
   * it's setting empty user permissions and thus disabling sideNav items
   * on app first load after registration process.
   * Checking here that cacheLocation is set as localstorage only under Cypress.
   *
   * TODO: find a more reliable way to check Cypress environment
   * without breaking CI workflow
   */

  const isCypress = typeof window.Cypress !== 'undefined';
  const cacheLocation = isCypress ? 'localstorage' : 'memory';

  useEffect(() => {
    const initAuth0 = async () => {
      const codeAndStateParamsExist =
        window.location.search.includes('code=') &&
        window.location.search.includes('state=');
      const auth0FromHook = await createAuth0Client({
        domain: AUTH_CONFIG.domain || '',
        client_id: AUTH_CONFIG.clientID || '',
        redirect_uri: AUTH_CONFIG.redirectUri,
        audience: AUTH_CONFIG.audience,
        cacheLocation,
      });
      setAuth0(auth0FromHook);

      const newAppState = {
        appState: { targetPath: window.location.pathname },
      };

      if (codeAndStateParamsExist) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState?.targetPath || null);
        } catch (err) {
          window?.DD_RUM?.addError(err);
          return auth0FromHook.loginWithRedirect(newAppState);
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        initializeSession(auth0FromHook, setSession, dispatch);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const getIdTokenClaims = () => auth0Client?.getIdTokenClaims();
  const loginWithRedirect = (appState?: RedirectLoginOptions) =>
    auth0Client?.loginWithRedirect(appState);
  const getTokenSilently = () => auth0Client?.getTokenSilently();
  const logout = () => auth0Client?.logout({ returnTo: auth0LogoutUrl });
  const state = {
    isAuthenticated,
    loading,
    getIdTokenClaims,
    loginWithRedirect,
    getTokenSilently,
    logout,
    ...session,
    setCustomerId,
  };

  if (customerId) state.customerId = customerId;

  return (
    <Auth0Context.Provider value={state}>
      {!!auth0Client && children}
    </Auth0Context.Provider>
  );
};
